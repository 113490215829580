@layer utilities {
    .max-w-80 {
        max-width: 20rem;
    }

    .max-w-40 {
        max-width: 10rem;
    }

    .max-w-15 {
        max-width: 15rem;
    }

    .max-w-96 {
        max-width: 24rem;
    }

    .min-h-96 {
        min-height: 24rem;
    }

    .min-h-4 {
        min-height: 1rem;
    }

    .min-h-32 {
        min-height: 8rem;
    }

    .min-w-32 {
        min-width: 8rem;
    }

    .min-w-24 {
        min-width: 6rem;
    }

    .min-w-20 {
        min-width: 5rem;
    }

    .min-w-40 {
        min-width: 10rem;
    }

    .min-w-52 {
        min-width: 13rem;
    }

    .min-w-60 {
        min-width: 15rem;
    }

    .min-w-160 {
        min-width: 28rem;
    }

    .min-w-6 {
        min-width: 1.5rem;
    }

    .flex-2 {
        flex: 2 1;
    }

    .h-min {
        height: min-content;
    }

    .h-13 {
        height: 3.25rem;
    }

    .h-12\.5 {
        height: 3.125rem;
    }

    .flex-basis-14 {
        flex-basis: 14rem;
    }

    .w-auto\! {
        width: auto !important;
    }

    .border-t-primary {
        border-top-color: #eea317;
    }

    .mb-20 {
        margin-bottom: 5rem;
    }
}

.w-42 {
    width: 10.5rem;
}

.z-9999 {
    z-index: 9999;
}

.focus-bkblue:focus {
    --tw-ring-opacity: 1;
    outline: 1px solid transparent;
    outline-offset: 1px;
    --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: rgba(0, 155, 223, var(--tw-ring-opacity));
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    border-color: rgba(0, 155, 223, var(--tw-ring-opacity));
}
