@keyframes transition-bg-left {
    from {
        background-position-x: calc(0% + 40px);
    }
    to {
        background-position-x: left;
    }
}

@keyframes transition-bg-top {
    from {
        background-position-y: calc(0% + 30px);
    }
    to {
        background-position-y: top;
    }
}

@keyframes shake {
    0% {
        transform: translateX(0px);
    }
    10% {
        transform: translateX(-1px);
    }
    20% {
        transform: translateX(-3px);
    }
    30% {
        transform: translateX(3px);
    }
    40% {
        transform: translateX(-3px);
    }
    50% {
        transform: translateX(3px);
    }
    60% {
        transform: translateX(-3px);
    }
    70% {
        transform: translateX(3px);
    }
    80% {
        transform: translateX(-3px);
    }
    90% {
        transform: translateX(-1px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes yellowfade {
  from {
    background: #ff9;
  }
  to {
    background: transparent;
  }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}
