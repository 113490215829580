// Focus
[type='checkbox'],
[type='radio'] {
    @apply focus:ring-1;
    @apply focus:outline-none;
    @apply focus:ring-bkblue;
}

input[type='text'][disabled],
input[type='text'].readonly {
    @apply bg-gray-light;
    @apply border-gray-300;
}

.turbo-progress-bar {
    @apply bg-bkblue;
}

.je-view-content {
    height: calc(100vh - 225px);
}

.variants-view-content {
    height: calc(100vh - 225px);
}

.balance-transaction-view-content {
    // height: calc(100vh - 225px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.entity-view-content {
    max-height: calc(100vh - 140px);
}

.square-locations-content {
    max-height: calc(100vh - 160px);
}

.custom-shadow{
	box-shadow: 0 0 6px 0px #bdbdbc;
}

.raw-data {
    max-width: 1000px;
}

.je-view-content-footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.balance-transaction-view-footer {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 64rem) {
    .je-popup {
        width: calc(100vw - 14rem);
        max-width: 55rem;
    }
}

.billing-plans {
    max-width: 1024px;
}

form {
    button.btn-danger:disabled {
        @apply opacity-50;
    }
}

// form_controller.js
form button.processing[data-processing-content] {
    @apply flex justify-center;

    label,
    .label {
        @apply ml-2;
        &:not(.processing-label) {
            display: none;
        }
    }

    &::before {
        content: '';
        mask: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='none' viewBox='0 0 24 24'%3E%3Ccircle style='opacity: 0.25' cx='12' cy='12' r='10' stroke='currentColor' stroke-width='4'%3E%3C/circle%3E%3Cpath class='opacity: 0.75' fill='currentColor' d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'%3E%3C/path%3E%3C/svg%3E%0A");
        @apply animate-spin;
        @apply h-5;
        @apply w-5;
        @apply bg-current;
    }

    &:not([data-processing-content=''])::before {
        @apply -ml-1;
        @apply mr-3;
    }

    &::after {
        content: attr(data-processing-content);
    }
}
.focus-none:focus {
    outline: none;
}

aside,
[data-controller*='active-item-tracker'] {
    nav {
        .active {
            @apply bg-gray-50;
            @apply hover:bg-white;
            > svg {
                @apply text-black;
                @apply -ml-1 mr-3;
            }
            > span {
                @apply block;
            }
        }
    }
}

// label.toggle-checkbox

.toggle-checkbox:checked {
    left: 1rem;
    border-color: #68d391;
    @apply right-0;
    @apply border-green-400;
    background-color: white;
    &:hover,
    &:focus {
        background-color: white;
        border-color: #68d391;
    }

    background-image: none;
    + .toggle-label {
        @apply bg-green-400;
        background-color: #68d391;
    }

    &.toggle-disabled{
        border-color: #68d391;
    }
}

// focus rings for valid and invalid inputs

input:not([data-input-target='control']).invalid {
    @apply ring-1;
    @apply outline-none;
    @apply ring-red-500;
    @apply focus:ring-1;
    @apply focus:border-red-500;
    @apply focus:ring-red-500;
}

[data-controller*='input'][data-input-valid-value='false']:not(.donot-apply):not(:focus-within) {
    label {
        @apply text-bkred;
    }
    input,
    select {
        @apply border-bkred border-2;
    }
}

[data-controller*='input'][data-input-valid-value='warning']:not(.donot-apply):not(:focus-within) {
    label {
        @apply text-primary-warning;
    }
    input,
    select {
        @apply border-primary border-2;
    }
}

input:not([data-input-target='control']).valid {
    @apply ring-1;
    @apply outline-none;
    @apply ring-green-500;
    @apply focus:ring-1;
    @apply focus:border-green-500;
    @apply focus:ring-green-500;
}

[data-input-target='hint'] {
    @apply flex items-center text-bkred mt-0.5 font-bold;
    &:before {
        height: 1rem;
        width: 1rem;
        @apply mr-1 inline-block flex-shrink-0 self-start;
    }
    &.error {
        &:before {
            content: '';
            background-image: url('~alert_icon.svg');
        }
    }
    &.success {
        @apply text-bkgreen-success;
        &:before {
            content: '';
            background-image: url('~success-check.svg');
        }
    }

    &.warning {
        @apply text-primary-warning;
    }
}

// Show * after required label
label.required::after {
    content: '*';
    @apply text-sm;
    @apply ml-0.5;
    @apply text-red-500;
}

.value-mask {
    overflow-wrap: anywhere;
}

.option.selected::before {
    content: '';
    @apply h-4;
    @apply w-4;
    @apply flex-shrink-0;
    @apply inline-flex;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('~check-mark-blue.svg');
}

.new-entity::before {
    content: '';
    @apply h-4;
    @apply w-4;
    @apply flex-shrink-0;
    @apply inline-flex;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background-image: url('~plus.svg');
}

.option {
    &:not(.no-default-selection) {
        padding-left: 32px;
    }
    &.highlight {
        @apply bg-bkgray-light;

        &.selected::before {
            position: absolute;
            left: 8px;
            top: 50%;
            transform: translateY(-50%);
            background-image: url('~check-mark-blue.svg');
        }
    }
}

.popper-wide {
    max-width: 20rem !important;
}

.tooltip {
    background: #333;
    color: white;
    font-weight: 500;
    padding: 4px 8px;
    font-size: 13px;
    border-radius: 4px;
    display: none;
    max-width: 12rem;
    z-index: 10;
}
.arrow,
.arrow::before {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
}

.arrow {
    visibility: hidden;
}

.arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
    top: 0;
    left: 0;
}
.tooltip[data-popper-placement^='top'] > .arrow {
    bottom: -4px;
}

.tooltip[data-popper-placement^='bottom'] > .arrow {
    top: -4px;
}

.tooltip[data-popper-placement^='left'] > .arrow {
    right: -4px;
}

.tooltip[data-popper-placement^='right'] > .arrow {
    left: -4px;
}

.tooltip[data-show] {
    display: block;
}

[data-tooltip] {
    @apply relative;
    @apply cursor-pointer;
    &:hover {
        &::after {
            @apply inline-block;
            @apply font-normal;
            @apply text-sm;
            @apply rounded-lg;
            content: attr(data-tooltip);
            @apply absolute;
            @apply left-full;
            @apply -top-2;
            @apply ml-2;
            @apply p-2;
            @apply border;
            background-color: #333;
            @apply shadow;
            @apply w-48;
            @apply z-20;
            @apply text-white;
            @apply pointer-events-none;
        }

        &::before {
            content: '';
            @apply pointer-events-none;
            @apply absolute;
            @apply left-full;
            @apply top-1;
            right: 0.625rem;
            @apply border-white;
            z-index: 30;
            border-color: transparent #333 transparent transparent;
            border-top-width: 0.3125rem;
            border-right-width: 0.625rem;
            border-bottom-width: 0.3125rem;
        }
    }
    &[data-tooltip-position='bottom'] {
        @media only screen and (max-width: 1024px) {
            &:hover {
                &::after {
                    @apply right-0;
                    @apply top-full;
                    @apply ml-0;
                    @apply mt-2;
                    left: unset;
                }
                &::before {
                    @apply top-full;
                    left: unset;
                    transform: rotateZ(90deg);
                }
            }
        }
    }
    &[data-tooltip-position='top'] {
        @media only screen and (max-width: 1024px) {
            &:hover {
                &::after {
                    @apply right-0;
                    @apply left-0;
                    @apply bottom-full;
                    @apply ml-0;
                    @apply mb-2;
                    top: unset;
                }
                &::before {
                    @apply bottom-full;
                    @apply left-1/2;
                    top: unset;
                    right: unset;
                    transform: rotateZ(-90deg);
                }
            }
        }
    }
    &[data-tooltip-position='left'] {
        @media only screen and (max-width: 1024px) {
            &:hover {
                &::after {
                    @apply right-full;
                    @apply ml-0;
                    @apply mr-2;
                    left: unset;
                    top: unset;
                }
                &::before {
                    @apply right-full;
                    left: unset;
                    transform: rotateZ(180deg);
                }
            }
        }
    }
}

.tooltip {
    @apply hidden;
}
[data-tooltip-container] {
    @apply relative;
    @apply cursor-pointer;
    &:hover,
    &:focus {
        .tooltip {
            @apply inline-block;
            @apply font-normal;
            @apply text-sm;
            @apply rounded;
            @apply absolute;
            @apply left-full;
            @apply -top-2;
            @apply ml-2;
            @apply p-2;
            @apply border;
            @apply bg-white;
            @apply shadow;
            @apply w-32;
            @apply z-20;
            @apply text-gray-700;
        }

        &::before {
            content: '';
            @apply absolute;
            @apply left-full;
            @apply top-1;
            right: 0.625rem;
            @apply border-white;
            z-index: 30;
            border-color: transparent white transparent transparent;
            border-top-left-radius: 100%;
            border-bottom-left-radius: 100%;
            border-top-color: rgba(204, 204, 204, 0.25);
            border-bottom-color: rgba(204, 204, 204, 0.25);
            border-top-width: 0.3125rem;
            border-right-width: 0.625rem;
            border-bottom-width: 0.3125rem;
        }
    }
}

.flatpickr-calendar.open {
    .flatpickr-monthDropdown-months,
    .numInput.cur-year {
        font-size: 1rem;
    }
}

input[type='checkbox']:disabled {
    @apply border-gray-300;
}

.form-disabled {
    color: white !important;
    background-color: #efefef !important;
}

.disabled-url{
    pointer-events: none;
    cursor: default;
}

[type='radio'] {
    height: 1.375rem;
    width: 1.375rem;
    border-width: 1px;
    @apply -mt-0.5 cursor-pointer;
    @apply relative;
    &:hover,
    &:checked {
        + label {
            @apply text-bkblue;
        }
    }
    &:disabled {
        @apply border-bkgray-300;
        @apply cursor-default;
        + label {
            @apply cursor-default;
            @apply text-bkgray;
        }
    }
    &::before {
        content: '';
        top: 0.6875rem;
        left: 0.6875rem;
        height: 0px;
        width: 0px;
        @apply block absolute bg-bkblue rounded-full;
        @apply ease-in duration-75;
    }
    &:checked {
        color: white;
        border-color: #6b7280 !important;
        &:before {
            top: 0.2rem;
            left: 0.2rem;
            height: 0.875rem;
            width: 0.875rem;
        }
        &:focus {
            box-shadow: none;
            + label {
                @apply text-bkblue;
            }
        }
    }
}

.radio-input {
    @apply border border-bkgray-50 flex items-center rounded-md cursor-pointer p-1 py-3;
    & > [type='radio'] {
        border-color: #bdbdbc !important;
        &::before {
            top: -1px;
            left: -1px;
            height: 1.375rem;
            width: 1.375rem;
            @apply block absolute bg-transparent rounded-full;
            @apply ease-in duration-75;
        }
    }
    &:hover {
        & > [type='radio'] {
            &::before {
                content: url('~grey-check.svg') !important;
            }
        }
        @apply bg-bkblue-50;
    }
    & > [type='radio'] {
        &:checked {
            border-color: #009bdf !important;
            &::before {
                content: url('~blue-check.svg') !important;
            }
            & ~ .label {
                font-weight: 500 !important;
                color: #009bdf !important;
            }
        }
    }
}

[data-controller*='accordion'] {
    &.open {
        .chev {
            @apply rotate-180;
        }
    }

    .chev {
        @apply transform;
        @apply rotate-0;
        @apply transition-all;
    }
}

#modal-background {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

input.highlight-selected-label:checked {
    + label {
        background-color: white;
        @apply border-primary-500;
        @apply font-semibold;
        @apply text-primary;
    }
}

input#billing_period:checked ~ .translate-dot {
    transform: translateX(100%);
    background-color: #009bdf;
}

.aasm- {
    &posted {
        @apply text-white bg-bkgreen;
    }
    &running {
        @apply text-white bg-secondary;
    }
    &enqueued {
        @apply text-white bg-secondary;
    }
    &unposted {
        @apply bg-gray-200 text-gray-800;
    }
    &failed {
        @apply bg-red-100 text-red-800;
    }
    &deleted {
        @apply text-white bg-bkred-300;
    }
    &all {
        @apply bg-gray-200;
    }
}

.notification- {
    &resolved {
        @apply text-white bg-bkgreen;
    }
    &pending {
        @apply text-white bg-secondary;
    }
    &ignored {
        @apply  bg-red-100 text-red-800;
    }

    &all {
        @apply bg-gray-200;
    }
}

.bktabs {
    .tab {
        @apply focus:outline-none focus:text-bkblue;
        &.active {
            @apply text-bkblue;
            @apply font-bold;
            > a {
                @apply text-bkblue;
                @apply font-bold;
            }
        }
    }
}

.btn-primary {
    @apply bg-bkblue text-white px-8 py-2 border border-transparent rounded-full;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bkblue inline-flex justify-center;
    @apply rounded-full;
    -webkit-appearance: none;
    min-width: 100px;
    &.wide {
        @apply px-16;
    }
}

.btn-secondary {
    @apply bg-primary text-white px-8 py-2 border border-transparent rounded-full;
    @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary inline-flex justify-center;
    @apply rounded-full;
    -webkit-appearance: none;
    min-width: 100px;
    &.wide {
        @apply px-16;
    }
}

.btn-arrow {
    position: relative;
    padding: 12px 40px;
    &::before {
        position: absolute;
        display: block;
        content: '';
        height: 20px;
        width: 20px;
        right: 8px;
        background-image: url('~caret-right.svg');
    }
}

.btn-primary-lite {
    @apply border border-transparent bg-transparent text-bkblue rounded-full border border-bkblue;
    @apply px-8 py-2 focus:outline-none focus:underline inline-flex  justify-center;
    -webkit-appearance: none;
    min-width: 100px;
    &.wide {
        @apply px-16;
    }
}

.btn-secondary-lite {
    @apply border border-transparent bg-transparent text-primary rounded-full border border-primary;
    @apply px-8 py-2 focus:outline-none focus:underline inline-flex  justify-center;
    -webkit-appearance: none;
    min-width: 100px;
    &.wide {
        @apply px-16;
    }
}

.btn-danger {
    @apply bg-bkred border border-transparent rounded-full py-2 px-8 inline-flex justify-center font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-bkred;
    -webkit-appearance: none;
    min-width: 100px;
    &.wide {
        @apply px-16;
    }
}

.btn-danger-lite {
    @apply border border-transparent bg-transparent text-bkred rounded-full border border-bkred;
    @apply px-8 py-2 focus:outline-none focus:underline inline-flex  justify-center;
    -webkit-appearance: none;
    min-width: 100px;
    &.wide {
        @apply px-16;
    }
}

input,
select {
    -webkit-appearance: none;
}

.common-input {
    @apply border border-bkgray-50 rounded-md py-2 px-3 sm:text-sm text-black shadow-sm placeholder-bkgray-100;
    &:focus {
        @apply outline-none;
        @apply border-bkblue;
        @apply ring-bkblue;
    }
}

select.common-input {
    @apply pr-8;
    &:invalid {
        @apply text-bkgray-100;
        option {
            @apply text-black;
        }
    }
}

.search-input {
    @apply relative;
    input {
        @apply border border-bkgray-50 rounded-md py-2 px-2 text-black shadow-sm placeholder-bkgray-100;
        &:focus {
            @apply outline-none;
            @apply border-bkblue;
            @apply ring-bkblue;
        }
    }
    &:before {
        content: url('~magnifying_glass.svg');
        @apply absolute block h-6 w-6 top-2 right-2;
    }
}


.truncate-container {
    overflow: hidden;
}

.truncate-container-text {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    cursor: pointer;
}

.expandable-truncate-container-text {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.product-table {
    min-height: calc(100vh - 335px);
    height: calc(100vh - 335px);
}

// Added these classes for collapsible sidenav in dekstop view
.collapse-icon-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 0.25rem;
    transition: background-color 0.2s;
    border-width: 2px;
}

.collapse-icon-container:hover {
    background-color: #009bdf;
}

.collapse-icon-container:hover path {
    fill: #ffffff;
}

.collapse-icon-container-flip {
    transform: rotate(-270deg);
}

.sidenav-container {
    transition: width 0.2s linear;
    transform-origin: right;
}
  
.sidenav-container-hide {
    width: 0;
    transform: scaleX(0);
}