@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import 'variables';
@import 'extensions';
@import 'layouts';
@import 'keyframes';
@import 'controls';


body {
    display: block;
    font-family: 'Montserrat', sans-serif; 
    color: #636466;
    letter-spacing: 0.2px;
  }

.font-family-ntr {
    font-family: 'NTR', sans-serif;
}

.flex > turbo-frame {
   &[data-busy] {
        &:not(#turbo-popup) {
            min-height: unset;
            flex-basis: 50px;
        }
    }
}

turbo-frame {
    @apply block;
     &[data-busy] {
        @apply relative;
        &:not(#turbo-popup) {
            min-height: 50px;
        }

        &::after {
            content: '';
            @apply bg-white w-full h-full absolute block opacity-50 top-0 bottom-0 left-0 right-0;
            z-index: 2;
        }

        &::before {
            z-index: 1;
            content: '';
            background: url("~bookkeep-icon-gray.svg") no-repeat;
            max-height: 70px;
            top: 50%;
            transform: translateY(-50%);
            @apply w-full h-full animate-pulse absolute bg-center bg-contain;
        }
    }
}

.card-bg {
    background-color: #ffffff;
    background-image: url("~provider-card-pattern.svg");
}

.cc-bg {
    background: linear-gradient(-125deg, #1d3349, #06121e 55%);
}

[data-transaction-type] {
    &::before {
        @apply rounded;
        @apply h-4;
        @apply w-4;
        @apply flex-shrink-0;
        @apply inline-block;
        @apply shadow;
        @apply inline-flex;
        @apply items-center;
        @apply justify-center;
        @apply font-semibold;
        @apply text-white;
        @apply text-xs;
    }

    &[data-transaction-type='credit']::before {
        content: 'C';
        @apply bg-green-600;
    }

    &[data-transaction-type='debit']::before {
        content: 'D';
        @apply bg-red-600;
    }
}

.subcategory-open {
    @apply flex-col;
    @apply 3xl:flex-row;
    .category-list {
        & > :not(.selected-jet-category) {
            @apply hidden;
            @apply 3xl:flex;
        }

        .selected-jet-category {
            @apply bg-gray-50;
            @apply border-cyan-400;
            @apply border-2;
            @apply 3xl:border-r-0;
            @apply relative;
            @apply rounded-lg;
            @apply 3xl:rounded-r-none;

            &::after {
                content: '';
                width: 2px;
                @apply 3xl:h-full;
                position: absolute;
                right: -2px;
                top: 0px;
                @apply bg-gray-50;
            }
        }
    }
    .subcat-list {
        @apply flex;
        @apply border-t-0;
        @apply rounded-t-none;
        @apply 3xl:rounded-t-lg;
        @apply 3xl:border-t-2;
    }
}

.subchannels :last-child .vertical-divider {
    @apply h-12.5;
}

.yellowfade {
  animation: yellowfade 2s;
}

.pulse {
    animation: pulse 0.5s ease-in-out;
}

.progress-bar {
    display: flex;
    height: 15px;
    margin-bottom: 5px;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    background-color: #f3f3f3;
}

.progress-segment {
    height: 100%;
    position: relative;
    transition: width 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    transition: width 1s ease-in-out;
}

.progress-segment.completed {
    background-color: #81c7847e; /* Light green */
    color: black; /* White text for better contrast */
}

.progress-segment.inprogress {
    background-color: #fff17695; /* Light yellow */
    color: #000; /* Dark text for better contrast */

    background: linear-gradient(
        90deg,
        #fff176 25%,
        white 50%,
        #fff176 75%
    );
    background-size: 200% 100%;
    animation: shimmerAnimation 5s linear infinite;
}

.progress-segment.unknown {
    background-color: #e0e0e0; /* Light grey */
    color: #000; /* Dark text for better contrast */
}

.progress-segment.not_started {
    background-color: #e0e0e0; /* Light grey */
    color: #000; /* Dark text for better contrast */
}

.progress-segment.aborted {
    background-color: #ffcc8089; /* Light orange */
    color: #000; /* Dark text for better contrast */
}

.progress-segment.failed {
    background-color: #f2642267;
    color: #000; /* Dark text for better contrast */
}

@keyframes shimmerAnimation {
    0% {
        background-position: -200% 0;
    }
    100% {
        background-position: 200% 0;
    }
}